import React from 'react'
import { LogInProvider } from './src/context/LogInContext'
import initializeDatadog from './src/helpers/datadog'
import { initializeHeap } from './src/helpers/heap'

export function onClientEntry() {
  const trackingPreferences = window.getCookieValue('tracking-preferences')
  if (trackingPreferences?.custom?.marketingAndAnalytics === false) {
    window.datadogRum = undefined
    window.heapReadyCb = undefined
    window.heap = undefined
  } else {
    initializeDatadog()
    initializeHeap()
  }
}

export const wrapRootElement = ({ element }) => {
  return <LogInProvider>{element}</LogInProvider>
}
