export const getEnv = () => {
  const environments = {
    development: 'development',
    staging: 'staging',
    production: 'production'
  }
  const env = environments[process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV]
  return env
}

export const getEnvVar = name => {
  return typeof window !== 'undefined' ? window.env[name] : process.env[name]
}
