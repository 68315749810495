import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

const defaultState = {
  isLoggedIn: false,
  toggleLogIn: () => {}
}

const LogInContext = React.createContext(defaultState)

const WHOIAMURL = `${process.env.GATSBY_BASE_URL}/whoami`

class LogInProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoggedIn: false
    }

    /*
    Is the customer logged in? If so, show My Account button.
    /whoami returns 401 error for unauthorized, 200 if logged in.
    */

    axios
      .get(WHOIAMURL)
      .then(() => this.setState({ isLoggedIn: true }))
      .catch(() => this.setState({ isLoggedIn: false }))
  }

  // TODO: PROS-376 review this function because it looks like it's not being used
  toggleLogIn = bool => this.setState({ isLoggedIn: bool })

  render() {
    const { children } = this.props
    const { isLoggedIn } = this.state

    // TODO: PROS-377 add useMemo and make sure the component is rerender when is needed
    return (
      <LogInContext.Provider
        // eslint-disable-next-line
        value={{
          isLoggedIn,
          toggleLogIn: this.toggleLogIn
        }}
      >
        {children}
      </LogInContext.Provider>
    )
  }
}

LogInProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

export default LogInContext

export { LogInProvider }
